@import '../../styles/app';

.root {
  .billingButton {
    margin-right: 1rem;

    @media screen and (max-width: 465px) {
      width: 100%;
      margin-right: 0;
      flex-direction: column;
      align-items: flex-end !important;
      margin-bottom: 1rem;
      button{
        width: 100%;
      }
    }
    @include media-breakpoint-down(md) {
      .customDropdown {
        margin-right: 0 !important;
        margin-bottom: 2rem;
      }
      span{
        margin-right: 2.5rem !important;
      }
    }
    span{
      margin-right: 0.5rem;
    }
  }
  .datePicker{
    @media screen and (max-width: 465px) {
      width: 100%;
    }
    border: 1px solid $text-color;
    color: $text-color;
    border-radius: 4px;
    height: 38px;
    padding: 0px 0 0 10px;
    cursor: pointer;
    &:focus, &:active{
      box-shadow: none;
      outline: 0;
    }
    &:disabled{
      color: $gray-400 !important;
      border-color: $gray-400 !important;
      background: transparent !important;
    }
  }
  .inputPicker{
    border: 0;
    background: transparent;
    cursor: pointer;
    color: $text-color;
    height: 38px;
    &:focus, &:active{
      box-shadow: none;
      outline: 0;
    }
  }
  .row {
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}