@import '../../styles/app';

.root {
  .dashboardBlock {
    @media (min-width: 1170px) and (max-width: 1280px) {
      display: none;
    }
  }
  .dailyLineChart {
    margin-top: -36px;
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
  .pieElements {
    display: flex;
    flex-direction: column;
    align-items: center;
    &Info {
      border-top: 3px solid theme-color("info")
    }
    &Success {
      border-top: 3px solid theme-color("success")
    }
    &Black {
      border-top: 3px solid $text-color
    }
    p {
      color: rgba($text-color, .6);
      white-space: nowrap;
    }
  }
  :global {
    .post-comments footer {
      margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
      padding: $widget-padding-vertical $widget-padding-horizontal;
    }

    .stat-item .name {
      margin-top: 10px;
    }
  }
  h5 {
    color: rgba($text-color, .6)
  }
  .dashboardImg {
    position: absolute;
    right: 0;
    bottom: -4px;
    width: 40%;
    @include media-breakpoint-down(md) {
      width: 100%;
      position: static;
      margin-bottom: -28px;
    }
    }
  .row {
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  :global {
    .input-group {
      overflow: hidden;
      width: 392px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }

    ul.nav {
      flex-wrap: nowrap;
    }

    .form-control {
      background-color: $white;
      &-light {
        background-color: #F0F0F0;
      }
    }


    .input-group-prepend {
      background-color: $white;
      transition: background-color ease-in-out 0.15s;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      &-light {
        background-color: #F0F0F0;
      }
    }

    .focus .input-group-prepend {
      background: #f8f9fa;
    }
  }
}

.headerSearchInput {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  &::placeholder {
    color: rgba(#6B6B6B, .5)
  }
  &Light {
    color: rgba(#6B6B6B, .5)
  }
}

.tableSearchForm{
  border: 1px solid $text-color;
  border-radius: 5px;
  width: 392px;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.table {
  :global(.table.table-sm) {
    td {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
}

.td_mq{
  @media only screen and (min-width: 1000px) and (max-width: 1199px) {
    max-width: 160px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    max-width: 200px;
  }
}