@import "../styles/app";

.auth-page {
  height: 100vh;
  background-color: #fff;
  padding: 0 15%;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  img.backImg {
    position: absolute;
    right: 15%;
    bottom: 0%;
    max-height: 85%;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.widget-auth {
  background: none !important;
  max-width: 360px;
  padding: 10px !important;
  height: 100vh;
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }

  input {
    border: 1px solid $gray-300;
    height: 45px;
    &::placeholder {
      color: $gray-300
    }
  }

  .form-check-label {
    font-size: 14px;
  }

  a {
    color: $text-color;
    font-size: 14px;
  }

  .widget-auth-info {
    &.text-center {
      font-size: 13px;
    }
    font-size: 18px;
    color: #464646;
    margin-bottom: 20px;
  }

  .auth-btn {
    width: 100%;
    height: 45px;
    font-size: 15px;
  }
}

.auth-footer {
  margin-bottom: 25px;
  font-size: 13px;
  color: #636c72;
  text-align: center;

  @media (min-height: 600px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.auth-logo {
  margin-top: 15px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: $font-weight-normal;

  i {
    font-size: 13px;
    margin: 0 20px;
  }
}


