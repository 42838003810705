@import '../../styles/app';

.customDropdown {
  background: #fff;
  margin-left: 1.5rem;
  box-shadow: 4px 4px 25px rgba(109, 105, 105, 0.25) !important;
}

.btnShadow {
  box-shadow: 4px 4px 25px rgba(109, 105, 105, 0.25) !important;
  text-transform: initial;
}

.dashboardButtons {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-end !important;
    margin-bottom: 2rem;
    .customDropdown {
      margin-right: 0 !important;
      margin-bottom: 2rem;
    }
    span{
      margin-right: 2.5rem !important;
    }
  }
  span{
    margin-right: 0.5rem;
  }
}

.spanTo{
  @include media-breakpoint-down(md) {
    span{
      margin-right: 3.5rem !important;
    }
  }
}

.datepickerButton {
  margin-right: 3%;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
    .react-datepicker-wrapper{
      width: 100% !important;
    }
  }
}


.datePicker{
  border: 1px solid $text-color;
  color: $text-color;
  border-radius: 4px;
  height: 38px;
  padding: 0px 0 0 10px;
  cursor: pointer;
  &:focus, &:active{
    box-shadow: none;
    outline: 0;
  }
  &:disabled{
    color: $gray-400 !important;
    border-color: $gray-400 !important;
    background: transparent !important;
  }
}
