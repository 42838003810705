@import '../../styles/app';
.root {
  z-index: 100;
  background: var(--navbar-bg);
  box-shadow: var(--navbar-shadow);
  height: $navbar-height;
  flex-wrap: nowrap;
  transition: background-color .2s ease, margin .2s ease;

  &Light {
    box-shadow: 4px 4px 25px rgba(109, 105, 105, 0.15);
  }

  .logo {
    color: var(--logo-color);
    font-size: 33px;
  }

  .toggleSidebarNav {
    height: calc(100% + 14px);
    background: $text-color;
  }

  a {
    color: #DBDBDB
  }

  .circleStyle {
    position: absolute;
    top: 19px;
    right: 9px;
    font-size: 9px;
  }

  .emailStyle {
    position: absolute;
    top: 19px;
    right: 5px;
    font-size: 9px;
  }

  @include media-breakpoint-down(sm) {
    padding: 7px 0;
  }
}

.navbarBrand {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  pointer-events: none;

  i {
    font-size: 10px;
  }
}

.justifySidebar{
  @include media-breakpoint-down(sm) {
    justify-content: space-between !important;
  }
}

.notificationsMenu {
  :global .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: $navbar-height !important;
  }
}

.notificationsWrapper {
  width: min-content;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 40px;
  width: 40px;
  background: $link-color;
  font-weight: 600;
  font-size: 18px;
}

.fullVersionBtn {
  color: #DBDBDB;
  border: 1px solid theme-color('warning');
  border-radius: 2px;
  margin-right: 30px;
  &:hover {
    background-color: theme-color('warning');
    a {
      color: #fff;
    }
  }
}
