@import "../../styles/app";

.root {
  position: fixed;
  width: $sidebar-width-open;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-color);
  transition: background-color 0.2s ease, margin 0.2s ease;
  @include scroll-bar($sidebar-scrollbar-bg);
}

.logoStyle {
  margin-right: 8px;
}

.sidebarWrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: $sidebar-width-open;
  transition: width $sidebar-transition-time ease-in-out;
  background-color: var(--sidebar-bg-color);
  z-index: 999;
  box-shadow: 4px 4px 25px rgba(109, 105, 105, 0.15);
}

.sidebarClose.sidebarWrapper {
  @include media-breakpoint-down(sm) {
    min-width: 0;
  }
  & .root {
    @include media-breakpoint-down(sm) {
      width: 0;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  background-color: $text-color;
  font-size: 30px;
  width: 100%;
  font-weight: $font-weight-light;
  transition: width $sidebar-transition-time ease-in-out;
  color: var(--logo-color);
  a {
    padding: 0 5px;
    text-decoration: none;
    white-space: nowrap;
  }
  span {
    font-weight: $font-weight-bold;
  }
}

.sidebarClose .logo {
  display: none;
}

.staticSidebar .logo {
  width: 100%;
}

.nav {
  padding: 40px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0;
}

.downNav {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
}

.navTitle {
  margin: 35px 0 0px 32px;
  font-size: $font-size-larger;
  transition: opacity $sidebar-transition-time ease-in-out;
  color: var(--sidebar-nav-title-color);

  @media (min-width: breakpoint-min(lg)) {
    opacity: 1;
  }
}

.sidebarClose .navTitle {
  opacity: 0;
}

.staticSidebar .navTitle {
  opacity: 1;
  transition: none;
}

.actionLink {
  color: #aaa;
  float: right;
  margin-right: 15px;
  margin-top: -1px;
}

.labelName {
  opacity: 1;
  transition: opacity $sidebar-transition-time ease-in-out;
}

.sidebarClose .labelName {
  opacity: 0;
}

.staticSidebar .labelName {
  transition: none;
  opacity: 1;
}

.glyphiconSm {
  font-size: 9px;
}

.sidebarLabels {
  margin-left: 5px;
  list-style-type: none;
  padding: 11px;
  padding-right: 15px;

  > li + li {
    margin-top: 10px;
  }

  li > a {
    font-size: $font-size-base;
    color: var(--sidebar-color);
    text-decoration: none;

    > i {
      font-size: 11px;
      vertical-align: 1px;
      transition: margin-left $sidebar-transition-time ease-in-out;
    }
  }
}

.sidebarClose {
  .sidebarLabels > li > a > i {
    margin-left: 8px;
    transition: margin-left $sidebar-transition-time ease-in-out;
  }
}

.staticSidebar {
  .sidebarLabels > li > a > i {
    transition: none;
    margin-left: 0;
  }
}

.sidebarAlerts {
  margin-bottom: $spacer * 2;
  transition: opacity $sidebar-transition-time ease-in-out;
  opacity: 1;
}

.sidebarClose .sidebarAlerts {
  opacity: 0;
}

.staticSidebar .sidebarAlerts {
  opacity: 1;
  transition: none;
}

.sidebarAlert {
  background: transparent;
  margin-bottom: 0;
  padding: 0.5rem 11px;
  padding-right: 15px;
}

.sidebarProgress {
  background-color: var(--sidebar-progress-bg-color);
}

.groupTitle {
  margin-bottom: 15px;
}
