@import "../../../styles/app";

.listGroup {
  display: block;
  height: 320px;
  overflow-y: scroll;

  &Account {
    height: auto;
  }

  .listGroupItem:first-child {
    border: none;
  }
  &Time {
    color: rgba($text-color, 0.6);
    font-size: 12px;
    font-weight: 600;
    padding: 15px 15px 0 15px;
  }
  &Title {
    padding: 10px 15px 0 15px;
    margin-bottom: -5px;
  }
  &TitleAccount {
    padding: 10px 15px 0 25px;
    margin-bottom: -5px;
  }
  &Subtitle {
    padding: 5px 15px 0 15px;
    margin-bottom: -5px;
    font-size: 12px;
    color: theme-color("success")
  }
  &SubtitleAccount {
    padding: 5px 15px 0 25px;
    margin-bottom: -5px;
    font-size: 12px;
    color: theme-color("success")
  }
  .messagesBadge {
    background-color: rgba(255, 234, 206, 0.51);
    border: 1px solid theme-color("success");
    color: $text-color;
    padding: 0.2em 0.4em;
    font-size: 10px
  }
  .seeMore {
    padding: 0 15px 0 15px;
    color: theme-color("warning");
    font-weight: $font-weight-base;
    display: inline-block;
    font-size: 14px;
  }
  .seeMoreBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.listGroupItem {
  border: 0;
  transition: background-color 0.15s ease-in-out;
  text-decoration: none;
  color: $text-color;
  border-left: none;
  border-right: none;
  display: block;
  font-size: 14px;
  font-weight: 300;

  &Account {
    border: 0;
    transition: background-color 0.15s ease-in-out;
    text-decoration: none;
    color: $text-color;
    border-left: none;
    border-right: none;
    display: block;
    font-size: 14px;
    font-weight: 300;
    padding: 15px 25px 15px 25px !important;
  }

  :global .progress {
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $black;
    }
  }

  &:hover {
    background-color: $list-group-hover-bg;

    :global .progress {
      background: $white !important;
    }
  }

  &:first-child {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  span {
    font-weight: 600;
  }
}

.notificationIcon {
  margin-right: 1rem;
  float: left;

  @include clearfix;
}
