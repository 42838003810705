// Meta

$enable-shadows:            true !default;

$glyphicons-halflings-include-bonus: false !default;
$glyphicons-halflings-class-prefix: glyphicon !default;
$glyphicons-halflings-font-base-size: 14px !default;

$glyphicons-halflings-font-path: '../fonts/glyphicons' !default;
$fa-font-path: '../fonts/font-awesome' !default;

/*
 * Typography
 * ======================================================================== */
$font-size-initial:       16px; // reset default browser value from 16px to 13px
$font-size-base:          1rem !default;
$font-size-lg:            1.25rem !default;
$font-size-sm:            0.875rem !default;
$font-size-larger:        1.1rem;
$font-size-mini:          0.9rem;
$font-size-index:         0.7rem;


//google font Source Sans Pro
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i&display=swap&subset=latin-ext');
$font-family-base: 'Source Sans Pro', sans-serif;
$headings-font-weight: 400;

// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d6dee5 !default;
$gray-400: #c1ccd3 !default;
$gray-500: #a3aeb7 !default;
$gray-600: #798892 !default;
$gray-700: #495057 !default;
$gray-800: #3c484f !default;
$gray-900: #29323a !default;
$black:    #000 !default;
$gray-light: $gray-100 !default;

$theme-colors: (
  primary: #0E3A61,
  secondary: #536B80,
  success: #05B59C,
  info: #5188E1,
  warning: #FFBF69,
  danger: #EF4C77,
  default: #AFC4D7,
  primary-light: #AFC4D7,
  success-light: #A1CCC6,
);

$link-color: #05B59C;
$info-color: #5188E1;
$text-color: #0E3A61 !default;

$font-weight-bold: 600 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-normal: 400 !default;
$font-weight-thin: 300 !default;
$font-weight-base: $font-weight-thin !default;

$addition-bg: $gray-100 !default;

$body-bg: #FAFAFA !default;
$body-bg-light: lighten($body-bg, 3%);

//== Components
//

$grid-gutter-width: 40px;

$content-padding: 40px;
$content-padding-sm: 25px;

$nav-link-padding: 10px;

$border-radius:          5px !default;
$border-radius-lg:       5px !default;
$border-radius-sm:       5px !default;

//== Sidebar
//

$sidebar-transition-time: 0.3s !default;

$sidebar-width-open: 265px;
$sidebar-width-closed: 50px;
$sidebar-transition-time: 0.3s !default;

$chat-sidebar-width-open: 250px;

$sidebar-border: 1px solid darken($body-bg, 2%);

$sidebar-item-border-color: transparent !default;
$sidebar-scrollbar-bg: #FFBF69;

$breadcrumb-divider: '>';
$breadcrumb-padding-y: 0;
$breadcrumb-bg: transparent;

$widget-bg: #fff;
$widget-title-color: $text-color;
$widget-shadow-designated: -1px 0 8px rgba(0,0,0,.2);
$widget-border-radius: 8px;

$input-btn-line-height: 1.5;

$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 0.8rem;

//== Theme Helper
//

$helper-width: 302px;

//== Navbar
//

$navbar-height:  66px !default;
$navbar-padding-y: 0 !default;
$navbar-padding-x: 32px !default;

// Custom navbar links
$navbar-link-color: $text-color !default;
$navbar-link-hover-color: $gray-800 !default;
$navbar-link-hover-bg: transparent !default;
$navbar-link-active-color: $gray-700 !default;
$navbar-link-active-bg: $gray-300 !default;
$navbar-link-disabled-color: $gray-400 !default;
$navbar-link-disabled-bg: transparent !default;

// Dropdowns
$dropdown-link-color: $text-color;

// Widgets
// -------------------------
$widget-padding-vertical: 23px !default;
$widget-padding-horizontal: 30px !default;
$widget-bg-color: $white;

//== Navs
//
$nav-tabs-active-link-hover-bg: $white !default;
$nav-tabs-active-link-hover-color: $gray-700 !default;

//== Forms
//
$input-focus-color: $text-color !default;
$input-focus-border-color: $text-color !default; //webkit focus color

//*  Popover  */
$popover-bg: #ebeff1 !default;
$popover-arrow-outer-color: $popover-bg !default;
$popover-arrow-color: $popover-bg !default;

// Badge
$badge-font-weight: 700;
$badge-font-size: 0.875em;
$badge-padding-y: 0.4em;
$badge-padding-x: 0.4em;

// Breadcrumbs
$breadcrumb-active-color: $gray-700;

// Progress bars
$progress-height: 0.65rem !default;
$progress-box-shadow: none;

// Alerts
$alert-transparent: 0.25;

// Buttons
$input-btn-padding-y:       0.375rem !default;
$input-btn-padding-x:       1rem !default;

$btn-default-bg: $addition-bg !default;
$btn-default-border: $addition-bg !default;

// Table
$table-border-width: 1px;

// Pagination
$pagination-color:                     $gray-700 !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    transparent !default;

$pagination-hover-color:               $gray-700 !default;
$pagination-hover-bg:                  transparent !default;
$pagination-hover-border:              transparent !default;
$pagination-hover-border-color:        transparent !default;

$pagination-active-color:              $gray-700 !default;
$pagination-active-bg:                 transparent !default;
$pagination-active-border:             transparent !default;
$pagination-active-border-color:       transparent !default;

$pagination-disabled-color:            $gray-700 !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           transparent !default;

// == Components
$padding-small-vertical:    0.25rem !default;
$padding-small-horizontal:  0.75rem !default;

// Screens
// -------------------------

$screen-lg-height: 670px !default;


//== Chat Sidebar
//
$chat-sidebar-width-open: 250px;

$chat-component-padding: 21px;
$chat-section-width: 330px;
